import React from 'react'
import { Link } from 'gatsby'

const Footer = class extends React.Component {
  render() {
    return (
      <div />
      // <footer className="footer has-text-white-ter">
        // <div className="content" style={{
          // "height": "100px"
        // }}>
        // </div>
      // </footer>
    )
  }
}

export default Footer
